import { ApolloClient } from "@apollo/client";
import { Chat, GetChatsDocument, GetChatsQuery } from "@koble/graphql";
import { MessageInstance } from "antd/lib/message/interface";

import { handleQuery } from "../../handlers";

export default async ({
  client,
  messageApi,
  errorMessage,
  successMessage,
}: {
  client: ApolloClient<object>;
  messageApi?: MessageInstance;
  errorMessage?: string;
  successMessage?: string;
}): Promise<Chat[]> => {
  const { chats } = await handleQuery<GetChatsQuery, NonNullable<unknown>>({
    client,
    queryDocument: GetChatsDocument,
    messageApi,
    errorMessage,
    successMessage,
  });

  if (chats === undefined || chats === null) {
    if (messageApi && errorMessage) {
      messageApi.error(errorMessage);
    }

    throw new Error("An error occurred while fetching chats.");
  }

  return chats.filter((chat): chat is Chat => chat !== null);
};
