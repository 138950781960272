import uploadBusinessBackgroundImage from "./mutations/uploadBusinessBackgroundImage";
import uploadJobBackgroundImage from "./mutations/uploadJobBackgroundImage";
import uploadUserRecruiterProfileImage from "./mutations/uploadUserRecruiterProfileImage";
import uploadUserStudentProfileImage from "./mutations/uploadUserStudentProfileImage";
import upsertThirdPartyJob from "./mutations/upsertThirdPartyJob";
import getBusinessById from "./queries/getBusinessById";
import getChats from "./queries/getChats";
import getCompatibilityScores from "./queries/getCompatibilityScores";
import getJobById from "./queries/getJobById";
import getMatches from "./queries/getMatches";

export {
  getBusinessById,
  getChats,
  getCompatibilityScores,
  getJobById,
  getMatches,
  uploadBusinessBackgroundImage,
  uploadJobBackgroundImage,
  uploadUserRecruiterProfileImage,
  uploadUserStudentProfileImage,
  upsertThirdPartyJob,
};
