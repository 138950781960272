import { ApolloClient } from "@apollo/client";
import {
  GetMatchesDocument,
  GetMatchesQuery,
  GetMatchesQueryVariables,
  Match,
} from "@koble/graphql";
import { MessageInstance } from "antd/lib/message/interface";

import handleQuery from "../../handlers/handleQuery";

export default async ({
  client,
  messageApi,
  errorMessage,
  successMessage,
}: {
  client: ApolloClient<object>;
  messageApi?: MessageInstance;
  errorMessage?: string;
  successMessage?: string;
}): Promise<Match[]> => {
  const { matches } = await handleQuery<
    GetMatchesQuery,
    GetMatchesQueryVariables
  >({
    client,
    queryDocument: GetMatchesDocument,
    messageApi,
    errorMessage,
    successMessage,
  });

  if (matches === undefined || matches === null) {
    if (messageApi && errorMessage) {
      messageApi.error(errorMessage);
    }

    throw new Error("An error occurred while fetching matches.");
  }

  return matches as Match[];
};
