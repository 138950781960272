"use client";

import { ReactNode, useState } from "react";
import { uploadUserStudentProfileImage } from "@koble/api/graphql";
import {
  AcademicProject,
  Certification,
  Education,
  ExtraCurricularActivity,
  UpdateUserStudentDetailedInformationDocument,
  UpdateUserStudentGeneralInformationDocument,
  UpdateUserStudentToolsAndSkillsDocument,
  UpsertAcademicProjectDocument,
  UpsertCertificationDocument,
  UpsertEducationDocument,
  UpsertExtraCurricularActivityDocument,
  UpsertWorkExperienceDocument,
  UserStudent,
  WorkExperience,
} from "@koble/graphql";
import { useGraphQL, useGraphQLRequest, useMessage } from "@koble/hooks";
import {
  isClientError,
  parseAndDisplayGraphqlErrors,
} from "@koble/utils/src/GraphQL";
import {
  percentCompleted,
  userStudentVisibleFields,
} from "@koble/utils/src/itemCompletion";
import { ClientError } from "graphql-request";

import UserStudentProfileContext, {
  DetailedInformation,
} from "@/common/UserStudentProfileContext/UserStudentProfileContext";

const UserStudentProfileProvider = ({
  children,
  initialUserStudentProfile,
}: {
  children: ReactNode;
  initialUserStudentProfile?: UserStudent;
}) => {
  const [userStudent, setUserStudent] = useState<UserStudent | undefined>(
    initialUserStudentProfile
  );
  const { messageApi } = useMessage();
  const { fileUploadGraphQLClient } = useGraphQL();
  const { authenticatedGraphQLRequestClient } = useGraphQLRequest();

  const getMidPoint = (startDate: any, endDate: any) => {
    const startDateTime = new Date(startDate);
    const endDateTime = endDate ? new Date(endDate) : new Date();

    return (startDateTime.getTime() + endDateTime.getTime()) / 2;
  };

  const profileDisplayErrors = async (
    error: any,
    defaultErrorMessage: string
  ) => {
    if (isClientError(error)) {
      await parseAndDisplayGraphqlErrors(error as ClientError, messageApi);
    } else {
      messageApi.error(defaultErrorMessage);
    }
  };

  const uploadProfileImage = async (file: File) => {
    if (!fileUploadGraphQLClient) return;

    await fileUploadGraphQLClient.resetStore();

    const newId = await uploadUserStudentProfileImage({
      client: fileUploadGraphQLClient,
      messageApi,
      variables: {
        file,
      },
      errorMessage: "Ocurrió un error al subir la imagen de perfil.",
      successMessage: "Se subió la imagen de perfil correctamente.",
    });

    setUserStudent((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        profileImageUrl: newId,
      };
    });
  };

  const updateGeneralInformation = async (values: Partial<UserStudent>) => {
    if (!authenticatedGraphQLRequestClient) return;

    try {
      await authenticatedGraphQLRequestClient.request(
        UpdateUserStudentGeneralInformationDocument,
        {
          firstName: values.firstName ?? "",
          lastName: values.lastName ?? "",
          areaId: values.area?.areaId ?? null,
          workScheduleTypeId:
            values.workScheduleType?.workScheduleTypeId ?? null,
        }
      );

      setUserStudent((prev) => {
        if (!prev) return prev;

        const newUserStudent: UserStudent = {
          ...prev,
          firstName: values.firstName ?? "",
          lastName: values.lastName ?? "",
          area: values.area ?? null,
          workScheduleType: values.workScheduleType ?? null,
        };

        const percent = percentCompleted<UserStudent>(
          newUserStudent,
          userStudentVisibleFields
        );

        return {
          ...newUserStudent,
          isProfileVisible: percent === 100,
        };
      });

      messageApi.success("Se actualizó los datos básicos correctamente");
    } catch (error) {
      await profileDisplayErrors(
        error,
        "Ocurrió un error al actualizar los datos básicos"
      );
    }
  };

  const updateDetailedInformation = async (values: DetailedInformation) => {
    if (!authenticatedGraphQLRequestClient) return;

    try {
      await authenticatedGraphQLRequestClient.request(
        UpdateUserStudentDetailedInformationDocument,
        {
          about: values.about ?? "",
          careerCompletionPercentage: values.careerCompletionPercentage ?? 0,
          careerId: values.careerData.career?.careerId,
          dateOfBirth: values.dateOfBirth ?? "",
          expectationIds:
            values.expectations?.map((e) => e.expectationId) ?? [],
          genderId: values.gender?.genderId ?? "",
          hobbyIds: values.hobbies?.map((h) => h.hobbyId) ?? [],
          interestingFact: values.interestingFact || null,
          newCareerRequestName: values.careerData.newCareerRequestName ?? null,
          newUniversityRequestName:
            values.universityData.newUniversityRequestName ?? null,
          placeId: values.place?.placeId ?? "",
          pronounId: values.pronoun?.pronounId ?? "",
          socialMediaUrls:
            values.socialMediaUrls?.map((s) => ({
              socialMediaId: s.socialMedia?.socialMediaId ?? "",
              url: s.url,
            })) ?? [],
          universityId: values.universityData.university?.universityId,
        }
      );

      setUserStudent((prev) => {
        if (!prev) return prev;

        const newUserStudent: UserStudent = {
          ...prev,
          about: values.about,
          careerCompletionPercentage: values.careerCompletionPercentage,
          career: values.careerData.career || null,
          dateOfBirth: values.dateOfBirth,
          expectations: values.expectations || [],
          gender: values.gender,
          place: values.place,
          interestingFact: values.interestingFact || null,
          // TODO: this should be brought from the server
          // TODO: also bring isProfileVisible from the server
          newCareerRequest: values.careerData.newCareerRequestName
            ? ({
                name: values.careerData.newCareerRequestName,
              } as any)
            : null,
          newUniversityRequest: values.universityData.newUniversityRequestName
            ? ({
                name: values.universityData.newUniversityRequestName,
              } as any)
            : null,
          pronoun: values.pronoun,
          hobbies: values.hobbies || [],
          socialMediaUrls: values.socialMediaUrls || [],
          university: values.universityData.university || null,
        };

        const percent = percentCompleted<UserStudent>(
          newUserStudent,
          userStudentVisibleFields
        );

        return {
          ...newUserStudent,
          isProfileVisible: percent === 100,
        };
      });

      messageApi.success("Se actualizó la información detallada correctamente");
    } catch (error) {
      await profileDisplayErrors(
        error,
        "Ocurrió un error al actualizar la información detallada"
      );
    }
  };

  const updateToolsAndSkills = async (values: Partial<UserStudent>) => {
    if (!authenticatedGraphQLRequestClient) return;

    try {
      await authenticatedGraphQLRequestClient.request(
        UpdateUserStudentToolsAndSkillsDocument,
        {
          keyCompetencyIds:
            values.keyCompetencies?.map((k) => k.keyCompetencyId) ?? [],
          languageLanguageLevelIds:
            values.languageLanguageLevels?.map((l) => {
              return {
                languageId: l.language?.languageId ?? "",
                languageLevelId: l.languageLevel?.languageLevelId ?? "",
              };
            }) ?? [],
          specialRequirementIds:
            values.specialRequirements?.map((s) => s.specialRequirementId) ??
            [],
          skills: values.skills?.map((s) => s.skillId) ?? [],
        }
      );

      setUserStudent((prev) => {
        if (!prev) return prev;
        const newUserStudent: UserStudent = {
          ...prev,
          keyCompetencies: values.keyCompetencies ?? [],
          languageLanguageLevels: values.languageLanguageLevels ?? [],
          specialRequirements: values.specialRequirements ?? [],
          skills: values.skills ?? [],
        };

        const percent = percentCompleted<UserStudent>(
          newUserStudent,
          userStudentVisibleFields
        );

        return {
          ...newUserStudent,
          isProfileVisible: percent === 100,
        };
      });

      messageApi.success(
        "Se actualizó las herramientas y competencias personales correctamente"
      );
    } catch (error) {
      await profileDisplayErrors(
        error,
        "Ocurrió un error al actualizar las herramientas y competencias personales"
      );
    }
  };

  const upsertEducation = async (values: Partial<Education>) => {
    if (!authenticatedGraphQLRequestClient) return;

    try {
      const { upsertEducation: id } =
        await authenticatedGraphQLRequestClient.request(
          UpsertEducationDocument,
          {
            educationId: values.educationId,
            about: values.about ?? "",
            careerId: values.career?.careerId ?? "",
            endDate: values.endDate,
            startDate: values.startDate ?? "",
            keyCompetencies:
              values.keyCompetencies?.map((k) => k.keyCompetencyId) ?? [],
            universityId: values.university?.universityId ?? "",
          }
        );

      setUserStudent((prev) => {
        if (!prev) return prev;

        const educations = prev.educations ?? [];

        const newEducations = educations.map((e) => {
          if (e.educationId === id) {
            return {
              ...e,
              about: values.about,
              career: values.career,
              endDate: values.endDate,
              startDate: values.startDate,
              keyCompetencies: values.keyCompetencies,
              university: values.university,
            };
          }
          return e;
        });

        if (values.educationId === undefined) {
          const newEducationMidPoint = getMidPoint(
            values.startDate,
            values.endDate
          );

          const index = newEducations.findIndex((e) => {
            const educationMidPoint = getMidPoint(e.startDate, e.endDate);
            return newEducationMidPoint > educationMidPoint;
          });

          // TODO: properly type this
          const newWorkExperience = {
            about: values.about,
            career: values.career,
            endDate: values.endDate,
            startDate: values.startDate,
            keyCompetencies: values.keyCompetencies,
            university: values.university,
            educationId: id,
          } as any;

          if (index === -1) newEducations.push(newWorkExperience);
          else newEducations.splice(index, 0, newWorkExperience);
        }

        return {
          ...prev,
          educations: newEducations,
        } as any;
      });

      messageApi.success("Se actualizó la educación correctamente");
    } catch (error) {
      await profileDisplayErrors(
        error,
        "Ocurrió un error al actualizar la educación"
      );
    }
  };

  const upsertWorkExperience = async (values: Partial<WorkExperience>) => {
    if (!authenticatedGraphQLRequestClient) return;
    try {
      const { upsertWorkExperience: id } =
        await authenticatedGraphQLRequestClient.request(
          UpsertWorkExperienceDocument,
          {
            about: values.about ?? "",
            endDate: values.endDate,
            startDate: values.startDate ?? "",
            placeId: values.place?.placeId ?? "",
            workExperienceId: values.workExperienceId,
            businessName: values.businessName ?? "",
            workModeId: values.workMode?.workModeId ?? "",
            jobTitle: values.jobTitle ?? "",
            keyCompetencies:
              values.keyCompetencies?.map((k) => k.keyCompetencyId) ?? [],
            workScheduleTypeId:
              values.workScheduleType?.workScheduleTypeId ?? "",
          }
        );

      setUserStudent((prev) => {
        if (!prev) return prev;

        const workExperiences = prev.workExperiences ?? [];

        const newWorkExperiences = workExperiences.map((w) => {
          if (w.workExperienceId === id) {
            return {
              ...w,
              about: values.about,
              endDate: values.endDate,
              startDate: values.startDate,
              place: values.place,
              businessName: values.businessName,
              workMode: values.workMode,
              jobTitle: values.jobTitle,
              keyCompetencies: values.keyCompetencies,
              workScheduleType: values.workScheduleType,
            };
          }
          return w;
        });

        if (values.workExperienceId === undefined) {
          const newWorkExperienceMidPoint = getMidPoint(
            values.startDate,
            values.endDate
          );

          const index = newWorkExperiences.findIndex((w) => {
            const workExperienceMidPoint = getMidPoint(w.startDate, w.endDate);
            return newWorkExperienceMidPoint > workExperienceMidPoint;
          });

          // TODO: properly type this
          const newWorkExperience = {
            about: values.about,
            endDate: values.endDate,
            startDate: values.startDate,
            place: values.place,
            businessName: values.businessName,
            workMode: values.workMode,
            jobTitle: values.jobTitle,
            keyCompetencies: values.keyCompetencies,
            workScheduleType: values.workScheduleType,
            workExperienceId: id,
          } as any;

          if (index === -1) newWorkExperiences.push(newWorkExperience);
          else newWorkExperiences.splice(index, 0, newWorkExperience);
        }

        return {
          ...prev,
          workExperiences: newWorkExperiences,
        } as any;
      });

      messageApi.success("Se actualizó la experiencia laboral correctamente");
    } catch (error) {
      await profileDisplayErrors(
        error,
        "Ocurrió un error al actualizar la experiencia laboral"
      );
    }
  };

  const upsertExtraCurricularActivity = async (
    values: Partial<ExtraCurricularActivity>
  ) => {
    if (!authenticatedGraphQLRequestClient) return;

    try {
      const { upsertExtraCurricularActivity: id } =
        await authenticatedGraphQLRequestClient.request(
          UpsertExtraCurricularActivityDocument,
          {
            extraCurricularActivityId: values.extraCurricularActivityId,
            about: values.about ?? "",
            endDate: values.endDate,
            startDate: values.startDate ?? "",
            placeId: values.place?.placeId ?? "",
            keyCompetencies:
              values.keyCompetencies?.map((k) => k.keyCompetencyId) ?? [],
            subTitle: values.subTitle ?? "",
            title: values.title ?? "",
          }
        );

      setUserStudent((prev) => {
        if (!prev) return prev;

        const extraCurricularActivities = prev.extraCurricularActivities ?? [];

        const newExtraCurricularActivities = extraCurricularActivities.map(
          (e) => {
            if (e.extraCurricularActivityId === id) {
              return {
                ...e,
                about: values.about,
                endDate: values.endDate,
                startDate: values.startDate,
                place: values.place,
                keyCompetencies: values.keyCompetencies,
                title: values.title,
                subTitle: values.subTitle,
              };
            }
            return e;
          }
        );

        if (values.extraCurricularActivityId === undefined) {
          const newExtraActivityMidPoint = getMidPoint(
            values.startDate,
            values.endDate
          );

          const index = newExtraCurricularActivities.findIndex((e) => {
            const extraActivityMidPoint = getMidPoint(e.startDate, e.endDate);
            return newExtraActivityMidPoint > extraActivityMidPoint;
          });

          // TODO: properly type this
          const newExtraCurricularActivity = {
            about: values.about,
            endDate: values.endDate,
            startDate: values.startDate,
            place: values.place,
            keyCompetencies: values.keyCompetencies,
            title: values.title,
            subTitle: values.subTitle,
            extraCurricularActivityId: id,
          } as any;

          if (index === -1)
            newExtraCurricularActivities.push(newExtraCurricularActivity);
          else
            newExtraCurricularActivities.splice(
              index,
              0,
              newExtraCurricularActivity
            );
        }

        return {
          ...prev,
          extraCurricularActivities: newExtraCurricularActivities,
        } as any;
      });

      messageApi.success(
        "Se actualizó la actividad extra-curricular correctamente"
      );
    } catch (error) {
      await profileDisplayErrors(
        error,
        "Ocurrió un error al actualizar la actividad extra-curricular"
      );
    }
  };

  const upsertCertification = async (values: Partial<Certification>) => {
    if (!authenticatedGraphQLRequestClient) return;

    try {
      const { upsertCertification: id } =
        await authenticatedGraphQLRequestClient.request(
          UpsertCertificationDocument,
          {
            certificationId: values.certificationId,
            about: values.about ?? "",
            endDate: values.endDate,
            startDate: values.startDate ?? "",
            placeId: values.place?.placeId ?? null,
            keyCompetencies:
              values.keyCompetencies?.map((k) => k.keyCompetencyId) ?? [],
            institutionName: values.institutionName ?? "",
            title: values.title ?? "",
          }
        );

      setUserStudent((prev) => {
        if (!prev) return prev;

        const certifications = prev.certifications ?? [];

        const newCertifications = certifications.map((e) => {
          if (e.certificationId === id) {
            return {
              ...e,
              about: values.about,
              endDate: values.endDate,
              startDate: values.startDate,
              place: values.place,
              keyCompetencies: values.keyCompetencies,
              institutionName: values.institutionName,
              title: values.title,
            };
          }
          return e;
        });

        if (values.certificationId === undefined) {
          const newCertificationMidPoint = getMidPoint(
            values.startDate,
            values.endDate
          );

          const index = newCertifications.findIndex((e) => {
            const certificationMidPoint = getMidPoint(e.startDate, e.endDate);
            return newCertificationMidPoint > certificationMidPoint;
          });

          // TODO: properly type this
          const newCertification = {
            about: values.about,
            endDate: values.endDate,
            startDate: values.startDate,
            place: values.place,
            keyCompetencies: values.keyCompetencies,
            institutionName: values.institutionName,
            title: values.title,
            certificationId: id,
          } as any;

          if (index === -1) newCertifications.push(newCertification);
          else newCertifications.splice(index, 0, newCertification);
        }

        return {
          ...prev,
          certifications: newCertifications,
        } as any;
      });

      messageApi.success("Se actualizó la certificación correctamente");
    } catch (error) {
      await profileDisplayErrors(
        error,
        "Ocurrió un error al actualizar la certificación"
      );
    }
  };

  const upsertAcademicProject = async (values: Partial<AcademicProject>) => {
    if (!authenticatedGraphQLRequestClient) return;

    try {
      const { upsertAcademicProject: id } =
        await authenticatedGraphQLRequestClient.request(
          UpsertAcademicProjectDocument,
          {
            academicProjectId: values.academicProjectId,
            universityId: values.university?.universityId ?? "",
            careerId: values.career?.careerId ?? "",
            startDate: values.startDate ?? "",
            endDate: values.endDate,
            about: values.about ?? "",
            title: values.title ?? "",
            keyCompetencies:
              values.keyCompetencies?.map((k) => k.keyCompetencyId) ?? [],
          }
        );

      setUserStudent((prev) => {
        if (!prev) return prev;

        const academicProjects = prev.academicProjects ?? [];

        const newAcademicProjects = academicProjects.map((e) => {
          if (e.academicProjectId === id) {
            return {
              ...e,
              about: values.about,
              endDate: values.endDate,
              startDate: values.startDate,
              university: values.university,
              career: values.career,
              title: values.title,
              keyCompetencies: values.keyCompetencies,
            };
          }
          return e;
        });

        if (values.academicProjectId === undefined) {
          const newAcademicProjectMidPoint = getMidPoint(
            values.startDate,
            values.endDate
          );

          const index = newAcademicProjects.findIndex((e) => {
            const academicProjectMidPoint = getMidPoint(e.startDate, e.endDate);
            return newAcademicProjectMidPoint > academicProjectMidPoint;
          });

          // TODO: properly type this
          const newAcademicProject = {
            about: values.about,
            endDate: values.endDate,
            startDate: values.startDate,
            university: values.university,
            career: values.career,
            title: values.title,
            keyCompetencies: values.keyCompetencies,
            academicProjectId: id,
          } as any;

          if (index === -1) newAcademicProjects.push(newAcademicProject);
          else newAcademicProjects.splice(index, 0, newAcademicProject);
        }

        return {
          ...prev,
          academicProjects: newAcademicProjects,
        } as any;
      });

      messageApi.success("Se actualizó el proyecto académico correctamente");
    } catch (error) {
      await profileDisplayErrors(
        error,
        "Ocurrió un error al actualizar el proyecto académico"
      );
    }
  };

  return (
    <UserStudentProfileContext.Provider
      value={{
        userStudent,
        setUserStudent,
        uploadProfileImage,
        updateGeneralInformation,
        updateDetailedInformation,
        updateToolsAndSkills,
        upsertEducation,
        upsertWorkExperience,
        upsertExtraCurricularActivity,
        upsertCertification,
        upsertAcademicProject,
      }}
    >
      {children}
    </UserStudentProfileContext.Provider>
  );
};

export default UserStudentProfileProvider;
