import {
  ApolloClient,
  OperationVariables,
  TypedDocumentNode,
} from "@apollo/client";
import errors from "@koble/errors";
import { MessageInstance } from "antd/lib/message/interface";

type MutationHandlerArgs<TData, TVariables extends OperationVariables> = {
  client: ApolloClient<object>;
  mutationDocument: TypedDocumentNode<TData, TVariables>;
  variables?: TVariables;
  successMessage?: string;
  errorMessage?: string;
  messageApi?: MessageInstance;
  ignoredErrorCodes?: string[];
};

const handleMutation = async <TData, TVariables extends OperationVariables>({
  client,
  mutationDocument,
  variables,
  successMessage,
  errorMessage,
  messageApi,
  ignoredErrorCodes = [],
}: MutationHandlerArgs<TData, TVariables>) => {
  try {
    const res = await client.mutate<TData, TVariables>({
      mutation: mutationDocument,
      variables,
      errorPolicy: "none",
    });

    if (messageApi && successMessage) {
      messageApi.success(successMessage);
    }

    return res.data;
  } catch (e: any) {
    const {
      graphQLErrors,
    }: {
      graphQLErrors: {
        extensions: {
          code: string;
        };
      }[];
    } = e;

    const codes = graphQLErrors?.map((error) => error.extensions?.code);
    const spanishErrors = codes
      ?.filter(
        (code) => !ignoredErrorCodes.includes(code) // Filter out ignored handlers
      )
      .map((code) => errors[code as string]?.spanish);

    if (messageApi && errorMessage && spanishErrors?.length > 0) {
      messageApi.error(`${errorMessage} ${spanishErrors.join(" ")}`);
    } else if (messageApi && errorMessage) {
      messageApi.error(errorMessage);
    }

    throw e;
  }
};

export default handleMutation;
