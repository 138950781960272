import { ApolloClient } from "@apollo/client";
import {
  UploadJobBackgroundImageDocument,
  UploadJobBackgroundImageMutation,
  UploadJobBackgroundImageMutationVariables,
} from "@koble/graphql";
import { MessageInstance } from "antd/lib/message/interface";

import { handleMutation } from "../../handlers";

export default async ({
  client,
  messageApi,
  errorMessage,
  successMessage,
  variables,
}: {
  client: ApolloClient<object>;
  messageApi?: MessageInstance;
  errorMessage?: string;
  successMessage?: string;
  variables: UploadJobBackgroundImageMutationVariables;
}): Promise<string> => {
  const response = await handleMutation<
    UploadJobBackgroundImageMutation,
    UploadJobBackgroundImageMutationVariables
  >({
    client,
    mutationDocument: UploadJobBackgroundImageDocument,
    messageApi,
    errorMessage,
    successMessage,
    variables,
  });

  if (
    response?.uploadJobBackgroundImage === undefined ||
    response?.uploadJobBackgroundImage === null
  ) {
    if (messageApi && errorMessage) {
      messageApi.error(errorMessage);
    }

    throw new Error("An error occurred while uploading job background image.");
  }

  return response?.uploadJobBackgroundImage as unknown as string;
};
