import {
  ApolloClient,
  OperationVariables,
  TypedDocumentNode,
} from "@apollo/client";
import errors from "@koble/errors";
import { MessageInstance } from "antd/lib/message/interface";

type QueryHandleArgs<TData, TVariables extends OperationVariables> = {
  client: ApolloClient<object>;
  queryDocument: TypedDocumentNode<TData, TVariables>;
  variables?: TVariables;
  successMessage?: string;
  errorMessage?: string;
  messageApi?: MessageInstance;
};

const handleQuery = async <TData, TVariables extends OperationVariables>({
  client,
  queryDocument,
  variables,
  successMessage,
  errorMessage,
  messageApi,
}: QueryHandleArgs<TData, TVariables>) => {
  try {
    const res = await client.query<TData, TVariables>({
      query: queryDocument,
      variables,
      errorPolicy: "none",
    });

    if (messageApi && successMessage) {
      messageApi.success(successMessage);
    }

    return res.data;
  } catch (e: any) {
    const {
      graphQLErrors,
      networkError,
    }: {
      graphQLErrors?: {
        extensions: {
          code: string;
        };
      }[];
      networkError?: {
        statusCode: number;
        result: {
          errors: {
            message: string;
          }[];
        };
      };
    } = e;

    if (networkError) {
      const statusCode = networkError.statusCode;

      if (messageApi && errorMessage) {
        messageApi.error(`(${statusCode}) ${errorMessage}`);
      }

      if (
        networkError.result &&
        networkError.result.errors &&
        networkError.result.errors.length > 0
      ) {
        throw new Error(networkError.result.errors[0].message);
      }
      throw e;
    }

    const codes = graphQLErrors?.map((error) => error.extensions?.code) ?? [];
    const spanishErrors = codes?.map((code) => errors[code as string]?.spanish);

    if (messageApi && errorMessage) {
      messageApi.error(`${errorMessage} ${spanishErrors.join(" ")}`);
    }

    throw e;
  }
};

export default handleQuery;
