import React from "react";
import { assets } from "@koble/assets/assets";
import { Avatar, Button, Card, Divider, Typography } from "antd";

const UserStudentOnboardingSuccessfulModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  if (!open) return null;

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.36)",
        zIndex: 9999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        style={{
          width: "100%",
          maxWidth: 560,
        }}
      >
        <div style={{ textAlign: "center" }}>
          <Avatar
            src={assets.KOBOT_HAPPY_LARGE}
            size={160}
            alt="Kobot"
            style={{
              backgroundColor: "#e6f4ff",
              paddingBottom: 0,
              margin: "15px 0",
            }}
          />

          <Typography.Title
            level={3}
            style={{
              letterSpacing: 0.5,
              marginBottom: 0,
            }}
          >
            ¡Felicidades!
          </Typography.Title>

          <Typography.Text style={{ fontSize: 15 }}>
            Has completado tu perfil y ahora eres visible en Koble.
          </Typography.Text>

          <Divider style={{ margin: "15px 0" }} />

          <div style={{ textAlign: "left", fontSize: 13 }}>
            <span style={{ fontWeight: "bold" }}>Ahora puedes:</span>
            <ul
              style={{
                listStyleType: "none",
                listStylePosition: "inside",
                marginBottom: 20,
              }}
            >
              <li style={{ marginLeft: 6 }}>
                • Aplicar a cualquier vacante en través de un Like.
              </li>
              <li style={{ marginLeft: 6 }}>
                • Hacer match con las empresas que muestran interés en tu
                perfil.
              </li>
            </ul>
            <span style={{ fontWeight: "bold", display: "block" }}>
              ¡Mantén actualizado tu perfil!
            </span>
            <span>
              Mejor información aumenta tus posibilidades de hacer matches.
            </span>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: 30,
          }}
        >
          <Button onClick={onClose} type="primary">
            ¡Entendido!
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default UserStudentOnboardingSuccessfulModal;
