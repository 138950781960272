"use client";

import React, { ReactNode } from "react";
import { useSearchParams } from "next/navigation";

import UserStudentOnboardingErrorModal from "@/app/onboarding/UserStudentOnboardingErrorModal";
import UserStudentOnboardingSuccessfulModal from "@/app/onboarding/UserStudentOnboardingSuccessfulModal";

const UserStudentLayoutClient = ({ children }: { children: ReactNode }) => {
  const searchParams = useSearchParams();

  const successfulOnboarding = searchParams.get("successfulOnboarding");

  const [modalOpen, setModalOpen] = React.useState(true);

  return (
    <>
      <UserStudentOnboardingSuccessfulModal
        open={successfulOnboarding === "true" && modalOpen}
        onClose={() => setModalOpen(false)}
      />
      <UserStudentOnboardingErrorModal
        open={successfulOnboarding === "false" && modalOpen}
        onClose={() => setModalOpen(false)}
      />
      {children}
    </>
  );
};

export default UserStudentLayoutClient;
