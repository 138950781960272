import { ApolloClient } from "@apollo/client";
import {
  UploadUserStudentProfileImageDocument,
  UploadUserStudentProfileImageMutation,
  UploadUserStudentProfileImageMutationVariables,
} from "@koble/graphql";
import { MessageInstance } from "antd/lib/message/interface";

import { handleMutation } from "../../handlers";

export default async ({
  client,
  messageApi,
  errorMessage,
  successMessage,
  variables,
}: {
  client: ApolloClient<object>;
  messageApi?: MessageInstance;
  errorMessage?: string;
  successMessage?: string;
  variables: UploadUserStudentProfileImageMutationVariables;
}): Promise<string> => {
  const response = await handleMutation<
    UploadUserStudentProfileImageMutation,
    UploadUserStudentProfileImageMutationVariables
  >({
    client,
    mutationDocument: UploadUserStudentProfileImageDocument,
    messageApi,
    errorMessage,
    successMessage,
    variables,
  });

  if (
    response?.uploadUserStudentProfileImage === undefined ||
    response?.uploadUserStudentProfileImage === null
  ) {
    if (messageApi && errorMessage) {
      messageApi.error(errorMessage);
    }

    throw new Error(
      "An error occurred while uploading the student profile image."
    );
  }

  return response?.uploadUserStudentProfileImage as unknown as string;
};
