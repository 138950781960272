import { ApolloClient } from "@apollo/client";
import {
  CompatibilityScore,
  GetCompatibilityScoresDocument,
  GetCompatibilityScoresQuery,
} from "@koble/graphql";
import { MessageInstance } from "antd/lib/message/interface";

import handleQuery from "../../handlers/handleQuery";

export default async ({
  client,
  messageApi,
  errorMessage,
  successMessage,
}: {
  client: ApolloClient<object>;
  messageApi?: MessageInstance;
  errorMessage?: string;
  successMessage?: string;
}): Promise<CompatibilityScore[]> => {
  const { compatibilityScores } = await handleQuery<
    GetCompatibilityScoresQuery,
    NonNullable<unknown>
  >({
    client,
    queryDocument: GetCompatibilityScoresDocument,
    messageApi,
    errorMessage,
    successMessage,
  });

  if (compatibilityScores === undefined || compatibilityScores === null) {
    if (messageApi && errorMessage) {
      messageApi.error(errorMessage);
    }

    throw new Error("An error occurred while fetching compatibility scores.");
  }

  return compatibilityScores.filter(
    (compatibilityScore): compatibilityScore is CompatibilityScore =>
      compatibilityScore !== null
  );
};
