import { ApolloClient } from "@apollo/client";
import {
  UploadBusinessBackgroundImageDocument,
  UploadBusinessBackgroundImageMutation,
  UploadBusinessBackgroundImageMutationVariables,
} from "@koble/graphql";
import { MessageInstance } from "antd/lib/message/interface";

import { handleMutation } from "../../handlers";

export default async ({
  client,
  messageApi,
  errorMessage,
  successMessage,
  variables,
}: {
  client: ApolloClient<object>;
  messageApi?: MessageInstance;
  errorMessage?: string;
  successMessage?: string;
  variables: UploadBusinessBackgroundImageMutationVariables;
}): Promise<string> => {
  const response = await handleMutation<
    UploadBusinessBackgroundImageMutation,
    UploadBusinessBackgroundImageMutationVariables
  >({
    client,
    mutationDocument: UploadBusinessBackgroundImageDocument,
    messageApi,
    errorMessage,
    successMessage,
    variables,
  });

  if (
    response?.uploadBusinessBackgroundImage === undefined ||
    response?.uploadBusinessBackgroundImage === null
  ) {
    if (messageApi && errorMessage) {
      messageApi.error(errorMessage);
    }

    throw new Error(
      "An error occurred while uploading business background image."
    );
  }

  return response?.uploadBusinessBackgroundImage as unknown as string;
};
