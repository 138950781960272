import { ApolloClient } from "@apollo/client";
import {
  Business,
  GetBusinessByIdDocument,
  GetBusinessByIdQuery,
  GetBusinessByIdQueryVariables,
} from "@koble/graphql";
import { MessageInstance } from "antd/lib/message/interface";

import { handleQuery } from "../../handlers";

export default async ({
  client,
  messageApi,
  errorMessage,
  successMessage,
  businessId,
}: {
  client: ApolloClient<object>;
  messageApi?: MessageInstance;
  errorMessage?: string;
  successMessage?: string;
  businessId: string;
}): Promise<Business> => {
  const { business } = await handleQuery<
    GetBusinessByIdQuery,
    GetBusinessByIdQueryVariables
  >({
    client,
    queryDocument: GetBusinessByIdDocument,
    messageApi,
    errorMessage,
    successMessage,
    variables: {
      businessId,
      cache: Math.floor(Math.random() * 1000000).toString(),
    },
  });

  if (business === undefined || business === null) {
    if (messageApi && errorMessage) {
      messageApi.error(errorMessage);
    }

    throw new Error("An error occurred while fetching business.");
  }

  return business as Business;
};
