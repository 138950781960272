import { ApolloClient } from "@apollo/client";
import {
  GetJobByIdDocument,
  GetJobByIdQuery,
  GetJobByIdQueryVariables,
  Job,
} from "@koble/graphql";
import { MessageInstance } from "antd/lib/message/interface";

import { handleQuery } from "../../handlers";

export default async ({
  client,
  messageApi,
  errorMessage,
  successMessage,
  jobId,
}: {
  client: ApolloClient<object>;
  messageApi?: MessageInstance;
  errorMessage?: string;
  successMessage?: string;
  jobId: string;
}): Promise<Job> => {
  const { job } = await handleQuery<GetJobByIdQuery, GetJobByIdQueryVariables>({
    client,
    queryDocument: GetJobByIdDocument,
    messageApi,
    errorMessage,
    successMessage,
    variables: {
      jobId,
      cache: Math.floor(Math.random() * 1000000).toString(),
    },
  });

  if (job === undefined || job === null) {
    if (messageApi && errorMessage) {
      messageApi.error(errorMessage);
    }

    throw new Error("An error occurred while fetching job.");
  }

  return job as Job;
};
