import { ApolloClient } from "@apollo/client";
import {
  UploadUserRecruiterProfileImageDocument,
  UploadUserRecruiterProfileImageMutation,
  UploadUserRecruiterProfileImageMutationVariables,
} from "@koble/graphql";
import { MessageInstance } from "antd/lib/message/interface";

import { handleMutation } from "../../handlers";

export default async ({
  client,
  messageApi,
  errorMessage,
  successMessage,
  variables,
}: {
  client: ApolloClient<object>;
  messageApi?: MessageInstance;
  errorMessage?: string;
  successMessage?: string;
  variables: UploadUserRecruiterProfileImageMutationVariables;
}): Promise<string> => {
  const response = await handleMutation<
    UploadUserRecruiterProfileImageMutation,
    UploadUserRecruiterProfileImageMutationVariables
  >({
    client,
    mutationDocument: UploadUserRecruiterProfileImageDocument,
    messageApi,
    errorMessage,
    successMessage,
    variables,
  });

  if (
    response?.uploadUserRecruiterProfileImage === undefined ||
    response?.uploadUserRecruiterProfileImage === null
  ) {
    if (messageApi && errorMessage) {
      messageApi.error(errorMessage);
    }

    throw new Error(
      "An error occurred while uploading the recruiter profile image."
    );
  }

  return response?.uploadUserRecruiterProfileImage as unknown as string;
};
