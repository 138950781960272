import { ApolloClient } from "@apollo/client";
import {
  MutationUpsertThirdPartyJobArgs,
  UpsertThirdPartyJobDocument,
  UpsertThirdPartyJobMutation,
} from "@koble/graphql";
import { MessageInstance } from "antd/lib/message/interface";

import { handleMutation } from "../../handlers";

export default async ({
  client,
  messageApi,
  errorMessage,
  successMessage,
  thirdPartyJob,
}: {
  client: ApolloClient<object>;
  messageApi?: MessageInstance;
  errorMessage?: string;
  successMessage?: string;
  thirdPartyJob: MutationUpsertThirdPartyJobArgs;
}): Promise<string> => {
  const response = await handleMutation<
    UpsertThirdPartyJobMutation,
    MutationUpsertThirdPartyJobArgs
  >({
    client,
    mutationDocument: UpsertThirdPartyJobDocument,
    messageApi,
    errorMessage,
    successMessage,
    variables: thirdPartyJob,
  });

  if (
    response?.upsertThirdPartyJob === undefined ||
    response?.upsertThirdPartyJob === null
  ) {
    if (messageApi && errorMessage) {
      messageApi.error(errorMessage);
    }

    throw new Error("An error occurred while upserting the third party job.");
  }

  return response?.upsertThirdPartyJob;
};
